import React from 'react';

function NavbarAltersklassen({onLogin, handleAltersklassenClick, altersklassenList}) {
    return (
        <nav className="navbardisziplin">
            {altersklassenList.map((altKl) => (
                <button key={altKl.code} onClick={() => handleAltersklassenClick(altKl.code)}>
                    {altKl.name}
                </button>
            ))}
        </nav>
    );
}

export default NavbarAltersklassen;
