import React, {useEffect, useState} from 'react';
import '../Setzliste.css';
import NavbarLiga from "../components/NavbarLiga";
import ApiService from "../services/ApiService";
import Setzliste from "../Setzliste";
import NavbarAltersklassen from "../components/NavbarAltersklassen";

function Ab10mEinzelwertung() {
    const [loading, setLoading] = useState(false);
    const [altersklassenList, setAltersklassenList] = useState([]);

    const [selectedAltersklasse, setSelectedAltersklasse] = useState(null);
    const [einzelwertungList, setEinzelwertungList] = useState(null);
    const [collectedErgebnisData, setCollectedErgebnisData] = useState([]);

    const handleKlasseClick = (selectedAltersklasse) => {
        console.log("in: " + selectedAltersklasse)
        setSelectedAltersklasse(selectedAltersklasse);
//TODO Klassen kommen an aus dem BE. hier gehts weiter
        ApiService.fetchEinzelwertung("AB10m", 2024, selectedAltersklasse)
            .then(data => {
                setEinzelwertungList(data);
            })
            .catch(error => console.error('Fehler beim Laden der Daten für die rechte Spalte:', error));
    };

    useEffect(() => {
        setLoading(true);
        ApiService.fetchAltersklassen()
            .then(data => {
                setAltersklassenList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Altersklassen:', error));
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="element-content element-background">
            <div className="grid-show-setzliste">
                <div className="left-panel-setzliste">
                    <NavbarAltersklassen altersklassenList={altersklassenList} handleAltersklassenClick={handleKlasseClick}></NavbarAltersklassen>
                </div>
                {einzelwertungList && (
                    <ul className="right-panel-setzliste">
                        {einzelwertungList.map((
                            current, index) => (
                            <li key={current.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                                    <label> {current.name} </label>
                                    <Setzliste mannschaftId={current.id}/>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
}

export default Ab10mEinzelwertung;
