import React, {useState} from 'react';
import './LigaTabelle.css';

function Listen({tableData}) {
    const [list, setList] = useState([]);

    return (
        <div className="border-tabelle">
            <div className="grid-ligaTabelle tabelle-header">
                <div className="tabelle-pos">Platz</div>
                <div className="tabelle-mannschaft">Mannschaft</div>
                <div className="tabelle-anzahl">Anz.</div>
                <div className="tabelle-einzelpunkte">Einzel</div>
                <div className="tabelle-punkte">Team</div>
            </div>
            {tableData.map((ariaCurrent, tabIndex) => (
                <div className="grid-ligaTabelle border-liste" key={tabIndex}>
                    <div className="tabelle-pos">{tabIndex + 1}</div>
                    <div className="tabelle-mannschaft">{ariaCurrent.mannschaftName}</div>
                    <div className="tabelle-anzahl">{ariaCurrent.anzahlWk}</div>
                    <div className="tabelle-einzelpunkte">{ariaCurrent.displayEinzel}</div>
                    <div className="tabelle-punkte">{ariaCurrent.displayPunkte}</div>
                </div>
            ))}
        </div>
    )
}

export default Listen;
