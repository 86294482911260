import React from 'react';

function Startseite() {
    return (
        <div className="element-content element-background">
            <h2>Element 1 Inhalt</h2>
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dignissim, urna a interdum bibendum,
                mauris velit hendrerit orci, vel mattis massa sapien eu quam. Nulla ultrices in arcu in volutpat. Sed a
                lacinia elit. Integer malesuada tortor a augue pellentesque, sit amet volutpat tellus tristique.
            </p>
            <p>
                Vivamus id odio vel urna ullamcorper condimentum. Phasellus bibendum, velit eu tincidunt auctor, nisl
                libero finibus eros, nec euismod felis elit id est. Fusce non efficitur arcu. Curabitur eu justo et arcu
                pellentesque pharetra.
            </p>
            <p>
                Aliquam erat volutpat. Vivamus at neque eget ante lacinia posuere a eget purus. Sed tincidunt velit et
                sapien tincidunt, nec hendrerit lorem volutpat. Sed in varius libero, ac luctus justo. Cras cursus
                fringilla mauris, ut tincidunt turpis malesuada ut.
            </p>
            <p>
                Aliquam erat volutpat. Vivamus at neque eget ante lacinia posuere a eget purus. Sed tincidunt velit et
                sapien tincidunt, nec hendrerit lorem volutpat. Sed in varius libero, ac luctus justo. Cras cursus
                fringilla mauris, ut tincidunt turpis malesuada ut.
            </p>
            <p>
                Aliquam erat volutpat. Vivamus at neque eget ante lacinia posuere a eget purus. Sed tincidunt velit et
                sapien tincidunt, nec hendrerit lorem volutpat. Sed in varius libero, ac luctus justo. Cras cursus
                fringilla mauris, ut tincidunt turpis malesuada ut.
            </p>
            <p>
                Aliquam erat volutpat. Vivamus at neque eget ante lacinia posuere a eget purus. Sed tincidunt velit et
                sapien tincidunt, nec hendrerit lorem volutpat. Sed in varius libero, ac luctus justo. Cras cursus
                fringilla mauris, ut tincidunt turpis malesuada ut.
            </p>
            <p>
                Aliquam erat volutpat. Vivamus at neque eget ante lacinia posuere a eget purus. Sed tincidunt velit et
                sapien tincidunt, nec hendrerit lorem volutpat. Sed in varius libero, ac luctus justo. Cras cursus
                fringilla mauris, ut tincidunt turpis malesuada ut.
            </p>

        </div>
    );
}

export default Startseite;
