import React, {useEffect, useState} from 'react';
import './Liste.css';
import ApiService from "./services/ApiService";

function Setzliste({mannschaftId}) {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);

    useEffect(() => {
        setLoading(true);
        ApiService.fetchSetzliste(2024, mannschaftId)
            .then(data => {
                console.log(JSON.stringify("setzliste: " + data));
                setLoading(false)
                setList(data);
            })
            .catch(error => console.error('Fehler beim Laden der Ergebnisse:', error));
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }
    return (
        <div>
            <div className="setzliste-tabelle setzliste-header">
                <div className="tabelle-pos">Platz</div>
                <div className="tabelle-mannschaft">Mannschaft</div>
                <div className="tabelle-anzahl">Anz.</div>
                <div className="tabelle-einzelpunkte">Einzel</div>
                <div className="tabelle-punkte">Team</div>
            </div>
            <ul className="grid-setzlisteTable">
                {list.map((current, index) => (
                    <li key={current.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                        <label> {current.id} </label>
                        {Object.entries(current.wks).map(([key, value]) => (
                            <label key={key}>WK{key}: {value}</label>
                        ))}
                        <label> {current.avg} </label>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default Setzliste;
